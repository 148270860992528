import { useQuery } from "@tanstack/react-query";
import { ErrorMessage, FormikValues, useFormikContext } from "formik";
import { get } from "lodash";
import { ReactNode, useMemo } from "react";
import Select, { MultiValue, PropsValue, SingleValue } from "react-select";
import CreatableSelect from "react-select/creatable";
import { SpinnerSize } from "admin/src/constants/enums/spinner-sizes";
import Spinner from "admin/src/ui/components/common/Spinner";
import { PaginatedResults } from "shared/api/types/pagination";
import { PaginationRequest } from "shared/api/types/pagination";
import { FiltersRequest } from "shared/filter-where-clause";
import ToolTip from "admin/src/ui/components/common/ToolTip";
import InfoIcon from "shared/components/icons/InfoIcon";

export type PillarFormSelectOption = {
  label: string | ReactNode;
  value?: string | number;
  disabled?: boolean;
};

type PillarFormSelectPaginationFunc<T> = (
  filters?: FiltersRequest,
  pagination?: PaginationRequest
) => Promise<PaginatedResults<T> | T[]>;

export type PillarFormSelectMenuProps<T> = {
  name: string;
  valueProperty?: string;
  displayProperty?: string;
  selectOptionQuery?: PillarFormSelectPaginationFunc<T>;
  placeholder?: string;
  initialOption?: PropsValue<PillarFormSelectOption>;
  localOptionsList?: PillarFormSelectOption[];
  searchable?: boolean;
  label?: string;
  onChange?: (newValue: string | number | (string | number)[]) => void;
  disabled?: boolean;
  additionalClasses?: string;
  labelClassName?: string;
  customMappingFunction?: (
    options: PaginatedResults<T> | T
  ) => PillarFormSelectOption;
  testid?: string;
  tooltip?: ReactNode;
  multiple?: boolean;
  clearable?: boolean;
  createNewSelectOptionQuery?: (query: string) => Promise<T>;
  allowNewOptions?: boolean;
  menuClassName?: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PillarFormSelectMenu = <T extends { [key: string]: any }>({
  name,
  valueProperty,
  displayProperty,
  selectOptionQuery,
  placeholder,
  label,
  testid,
  tooltip,
  localOptionsList,
  initialOption,
  disabled,
  clearable = true,
  searchable,
  multiple,
  labelClassName,
  additionalClasses,
  createNewSelectOptionQuery,
  customMappingFunction,
  menuClassName,
  allowNewOptions,
  onChange,
  ...props
}: PillarFormSelectMenuProps<T>) => {
  if (allowNewOptions && !createNewSelectOptionQuery) {
    throw new Error(
      "You can't create new options without providing an API request to create it on server"
    );
  }

  const { values, setFieldValue } = useFormikContext<FormikValues>();
  const value = get(values, name);

  const selectOptions = useQuery(
    [multiple ? "comboOptions" : "selectOptions", name],
    () => {
      return selectOptionQuery?.();
    },
    {
      enabled: !!selectOptionQuery,
      select: (responseData): PillarFormSelectOption[] => {
        if (!responseData) {
          return [];
        }

        const unwrappedResponseData =
          responseData instanceof Array ? responseData : responseData.results;

        if (customMappingFunction) {
          return unwrappedResponseData.map((value) =>
            customMappingFunction(value)
          );
        }
        return unwrappedResponseData.map((option: T) => ({
          label:
            option[
              displayProperty ??
                (typeof responseData === "object" ? "label" : "display")
            ],
          value: option[valueProperty ?? "value"],
        }));
      },
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );

  const options = selectOptions.data?.length
    ? selectOptions.data
    : localOptionsList || [];

  const defaultValue = useMemo(() => {
    if (initialOption) {
      return initialOption;
    }
    return multiple
      ? options.filter((option) => value?.includes(option.value))
      : options.find((option) => option.value === value);
  }, [options, value, initialOption]);

  if (
    (selectOptions.isLoading && !!selectOptionQuery) ||
    (!selectOptions.isSuccess && !!selectOptionQuery)
  ) {
    return <Spinner spinnerSize={SpinnerSize.Small} />;
  }

  const commonSelectProps = {
    defaultValue,
    id: name,
    value: options.find((option) => option.value === value),
    name: name,
    styles: {
      control: () => ({}),
      menu: (provided: any) => ({
        ...provided,
        backgroundColor: "white",
      }),
    },
    // All styles and classNames can be adjusted, so we can add more props with additionalClassNames when we want to
    // It seems that these classNames can not take our custom classes, that's why control classes is so long
    classNames: {
      control: () =>
        "flex rounded text-sm bg-neutral-light text-neutral-mid-700 border border-neutral-mid-200 focus:border-neutral-mid-300 focus:ring-neutral-mid-300 disabled:bg-neutral-light-550 disabled:text-neutral-light-900",
      menu: () =>
        `${name}-options !bg-neutral-light !shadow-lg ${menuClassName}`,
      option: () => `${name}-option`,
      indicatorSeparator: () => "block",
    },
    options,
    isClearable: clearable,
    onChange: (
      newValue:
        | MultiValue<PillarFormSelectOption>
        | SingleValue<PillarFormSelectOption>
        | null
    ) => {
      if (newValue === null) {
        setFieldValue(name, undefined);
        return;
      }

      const multiValue: PillarFormSelectOption[] | undefined =
        Array.isArray(newValue) && newValue.length ? newValue : undefined;

      const singleValue =
        !Array.isArray(newValue) && (newValue as PillarFormSelectOption)?.value
          ? (newValue as PillarFormSelectOption)
          : undefined;

      if (!multiValue && !singleValue) {
        return;
      }

      if (singleValue) {
        setFieldValue(name, singleValue.value);
        if (onChange) {
          onChange(singleValue.value || "");
        }
      } else {
        const onlyValues = multiValue?.map((option) => option?.value) ?? [];
        const values = onlyValues.filter(
          (value): value is string | number => value !== undefined
        );

        setFieldValue(name, values);

        if (!values.every((value) => !!value)) {
          return;
        }

        if (onChange) {
          onChange(values);
        }
      }
    },
    isOptionDisabled: (option: PillarFormSelectOption) => {
      return !!option.disabled;
    },
    placeholder,
    isSearchable: searchable,
    isDisabled: disabled,
    isMulti: multiple,
  };

  return (
    <div data-testid={testid || name} className={`${additionalClasses}`}>
      {label && (
        <label
          htmlFor={name}
          className={`mb-0.25 flex items-center ${labelClassName}`}
          hidden={!label}
        >
          {`${label}`}
          {tooltip && (
            <ToolTip
              className="cursor-pointer ml-1"
              tooltipClassName="mt-0.25 py-1 px-2 bg-neutral-light text-xs rounded-md border border-neutral-mid-300"
              tooltip={<div className="flex flex-col text-sm">{tooltip}</div>}
              placement="top"
            >
              <InfoIcon className="text-status-general-tint h-3 w-3" />
            </ToolTip>
          )}
        </label>
      )}
      {allowNewOptions ? (
        <CreatableSelect
          onCreateOption={(inputValue) =>
            createNewSelectOptionQuery?.(inputValue)
          }
          {...commonSelectProps}
          {...props}
        />
      ) : (
        <Select {...commonSelectProps} {...props} />
      )}
      <ErrorMessage
        component="span"
        className="text-danger-small"
        name={name}
      />
    </div>
  );
};

export default PillarFormSelectMenu;
