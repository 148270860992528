import { useQuery, useQueryClient } from "@tanstack/react-query";
import get from "lodash/get";
import merge from "lodash/merge";
import set from "lodash/set";
import { useMemo, useState } from "react";
import { apiRequestContractHandler } from "shared/api/apiRequestContractHandler";
import {
  getCustomReportIdContract,
  postCustomReportIdContract,
} from "shared/api/contracts/society/societyId/reports/reportId";
import PillarForm from "shared/components/pillar-form/PillarForm";
import PillarModalBody, {
  ModalMinWidth,
  PillarModalHandle,
} from "shared/components/pillar-modal/PillarModalBody";
import { CustomReportColumnConfig } from "shared/components/pillar-table/custom-reports/customReportColumnsMapper";
import { useCustomRouter } from "shared/context/CustomRouterContext";
import { useSocietySession } from "shared/context/SocietySessionContext";
import { findMatchingFormDesignElement } from "shared/helpers/report/findMatchingFormDesignElement";
import { formDesignTypeConverter } from "shared/helpers/report/formDesignTypeConverter";
import { getTrueKeys } from "shared/helpers/report/getTrueKeys";
import { processFormDesignData } from "shared/helpers/report/processFormDesignData";
import { SpinnerSize } from "admin/src/constants/enums/spinner-sizes";
import Spinner from "admin/src/ui/components/common/Spinner";
import { FormDesignElement } from "admin/src/ui/types/reports/types";

type EditCustomProfileReportColumnsModalProps = {
  modalRef: React.RefObject<PillarModalHandle>;
};

const initialFormValues = {
  profileId: true,
  societyUser: {
    lastName: true,
    firstName: true,
  },
  contactInformation: {
    email: true,
  },
};

const requiredCheckboxesTestId: Record<string, string> = {
  profileId: "test-profile-id",
  "societyUser.lastName": "test-society-user-last-name",
  "societyUser.firstName": "test-society-user-first-name",
  "contactInformation.email": "test-contact-information-email",
};

const EditCustomProfileReportColumnsModal = ({
  modalRef,
}: EditCustomProfileReportColumnsModalProps) => {
  const society = useSocietySession();
  const queryClient = useQueryClient();
  const router = useCustomRouter();
  const [isOpen, setIsOpen] = useState(false);

  const reportId = router?.query?.reportId;

  const {
    data: customProfileReport,
    isFetching: isCustomProfileReportLoading,
  } = useQuery({
    queryKey: ["reportId", String(reportId)],
    queryFn: async () =>
      apiRequestContractHandler(getCustomReportIdContract, {
        params: {
          societyId: society.societyId,
          reportId: Number(reportId),
        },
      }),
    enabled: !!(isOpen && reportId),
    refetchOnWindowFocus: false,
  });

  const initialDisplayColumns = customProfileReport?.displayColumns as
    | CustomReportColumnConfig<"string">[]
    | undefined;

  const mapInitialValues = initialDisplayColumns
    ?.filter((item) => get(initialFormValues, item.cellValuePath))
    ?.map((item) => ({
      name: item.cellValuePath,
      title: item.name,
      type: formDesignTypeConverter(item.type),
      isDisabled: get(initialFormValues, item.cellValuePath),
      isChecked: true,
    }));

  const createObjectFromArray = (paths: string[]) => {
    return paths.reduce((acc, path) => {
      return merge(acc, set({}, path, true));
    }, {});
  };

  const overrideInitialValues = initialDisplayColumns
    ? createObjectFromArray(
        initialDisplayColumns.map((item) => item.cellValuePath),
      )
    : undefined;

  const formDesign = society?.profileForm?.formDesign?.pages?.[0].elements;
  const processedFormDesignData = useMemo(
    () => processFormDesignData(formDesign, mapInitialValues),
    [formDesign, mapInitialValues],
  );

  const lastSingleItemIndex = useMemo(
    () =>
      processedFormDesignData
        ? processedFormDesignData.filter(
            (item) => item && !item.children?.length,
          ).length - 1
        : -1,
    [processedFormDesignData],
  );

  return (
    <PillarModalBody
      ref={modalRef}
      className="w-60 overflow-visible"
      title="Reporting Profile Fields Settings"
      testid="profile-fields-setting-modal"
      minW={ModalMinWidth.Xl_1200}
      onOpen={() => {
        setIsOpen(true);
      }}
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <p className="mb-2 -mt-1 text-center text-sm">
        Selected profile fields below will be appended to the beginning of each
        report/table in Pillar Admin. Click Save to proceed
      </p>
      <PillarForm
        overrideInitialValues={{
          ...initialFormValues,
          ...overrideInitialValues,
        }}
        handleSubmit={async (values) => {
          const formDesignData = processedFormDesignData;
          if (!formDesignData) return;

          const result = getTrueKeys(values).map((item: string) => {
            const matchingElement = findMatchingFormDesignElement(
              formDesignData,
              item,
            );

            return {
              name: matchingElement
                ? matchingElement.parentTitle
                  ? `${matchingElement.parentTitle} - ${matchingElement.title}`
                  : matchingElement.title || matchingElement.name
                : "",
              cellValuePath: item,
              type: matchingElement?.type || "",
            };
          });
          await apiRequestContractHandler(postCustomReportIdContract, {
            params: {
              societyId: society.societyId,
              reportId: Number(reportId)!,
            },
            body: {
              displayColumns: result,
              exportColumns: result,
            },
          });

          modalRef.current?.close();
          queryClient.invalidateQueries({
            queryKey: ["reportId", String(reportId)],
          });
        }}
      >
        {isCustomProfileReportLoading ? (
          <Spinner spinnerSize={SpinnerSize.Large} />
        ) : (
          <div className="grid grid-cols-3 gap-y-1 mb-2">
            {processedFormDesignData?.map((item: FormDesignElement, index) =>
              item?.children ? (
                <div className="flex gap-y-1 flex-col" key={item.name}>
                  <p className="mt-1.5">{item.title}</p>
                  {item.children.map((subItem: FormDesignElement) => (
                    <div key={subItem.name}>
                      <PillarForm.Checkbox
                        label={
                          <div className="flex flex-wrap leading-normal">
                            <span className="text-title text-sm font-semibold">
                              {subItem.title ?? subItem.name}
                            </span>
                            <p className="ml-0.5 text-sm">({subItem.name})</p>
                          </div>
                        }
                        name={subItem.name}
                        additionalClasses="!flex-row-reverse items-center justify-end gap-x-1 mb-0.5"
                        disabled={subItem.isDisabled}
                        isChecked={subItem.isChecked}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <>
                  <div key={item.name}>
                    <PillarForm.Checkbox
                      label={
                        <div className="flex flex-wrap leading-normal">
                          <span className="text-title text-sm font-semibold">
                            {item.title ?? item.name}
                          </span>
                          <p className="ml-0.5 text-sm">({item.name})</p>
                        </div>
                      }
                      data-testid={
                        lastSingleItemIndex === index
                          ? "test-custom-profile-report-checkbox"
                          : requiredCheckboxesTestId?.[item.name]
                          ? requiredCheckboxesTestId[item.name]
                          : undefined
                      }
                      name={item.name}
                      additionalClasses="!flex-row-reverse items-center justify-end gap-x-1 mb-0.5"
                      disabled={item.isDisabled}
                      isChecked={item.isChecked}
                    />
                  </div>
                  {lastSingleItemIndex === index && (
                    <div className="col-span-3"></div>
                  )}
                </>
              ),
            )}
          </div>
        )}

        <div className="flex w-full items-center justify-end gap-x-2">
          <PillarForm.Button
            className="button-regular-neutral-filled"
            label="Cancel"
            onClick={() => modalRef.current?.close()}
          />
          <PillarForm.LoadingSubmitButton
            className="w-fit"
            label="Save"
            disabled={!reportId}
            testid="custom-profile-report-modal-form-submit-button"
          />
        </div>
      </PillarForm>
    </PillarModalBody>
  );
};

export default EditCustomProfileReportColumnsModal;
